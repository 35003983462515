import React from 'react'
import { Link } from 'gatsby'

import { DONATION_BENEFITS_DATA } from '../content/donationBenefits'

const DonationBenefits = () => (
  <div
    className="
      bg-blue-800
      flex
      flex-col
      flex-wrap
      font-sans
      items-center
      justify-center
      px-12
      py-20
      w-screen
      text-white
      text-center
      tracking-wide
    "
  >
    <h2
      className="
        font-black
        leading-snug
        text-5xl
        max-w-2xl
      "
    >What You&lsquo;re Getting</h2>
    <p
      className="
        text-xl
        mt-8
        max-w-3xl
      "
    >As of today, thousands of podcasters worldwide rely on Podlove already. We see it as our responsibility to keep up the maintenance and development of all Podlove projects.</p>
    <p
      className="
        text-left
        mt-20
        mb-14
        max-w-3xl
      "
    >The Podlove team has been working on this project since 2012. From the start it was clear to all of us that Podlove should be Open Source. We&lsquo;re all fascinated by the medium  because it&lsquo;s one of the last bastions of online content publishing without gatekeepers. We don&lsquo;t want to see walled garden platforms to take over the podcasting ecosystem. Instead we want to help you as a podcaster to take things into your own hands and publish your podcasts how and where you see fit. Anyone with a creative idea and the means to speak out should be enabled to do so.</p>
    <div
      className="
        flex
        flex-wrap
        md:px-12
        max-w-6xl
      "
    >
      { DONATION_BENEFITS_DATA.benefits.map(item =>
        <Link
          key={JSON.stringify(item)}
          to={item.link}
          className="
            text-left
            py-12
            px-4
            w-full
            sm:w-1/2
            lg:w-1/3
          "
        >
          <div
            className="
              bg-blue-400
              rounded-md
              h-10
              my-4
              w-10
            "
          >
            <img src={item.icon} />
          </div>
          <h3
            className="
              font-bold
              pb-4
              text-xl
            "
          >{item.title}</h3>
          <p>{item.copy}</p>
        </Link>
      )}
    </div>
  </div>
)

export default DonationBenefits
