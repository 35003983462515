import React from 'react'

import DonationBenefits from '../components/donationBenefits'
import DonationOptions from '../components/donationOptions'
import OpenCollective from '../components/opencollective'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Seo from '../components/seo'

const NAV_ITEMS = [
  {
    name: 'How to support us',
    path: '#support-us',
  },
]

const DonationsPage = () => (
  <Layout
    subHeaderNavItems={NAV_ITEMS}
    subHeaderNavTitle={'Donate to Podlove'}
  >
    <Seo title="Support Podlove" />
    <Hero
      copy="There are many ways to keep the Podlove project up and running. But maybe you're one of these good-hearted people that go &quot;Alright, I believe that my contribution will directly benefit the development of free, open-source software for podcasters! I don't need any explanation from you!&quot;. In that case: Just click on this button here and leave this page for a place where you can put this noble intent to action. If you're not quite convinced yet and want to learn something more about what we're going to do with your money or how you can support us in other ways – read on!"
      cta="Support us on Open Collective"
      ctaLink="#opencollective"
      headline="Support Podlove"
    />
    <DonationBenefits />
    <DonationOptions />
    <OpenCollective />
  </Layout>
)

export default DonationsPage