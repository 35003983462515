export const DONATION_BENEFITS_DATA = {
  benefits: [
    {
      copy: 'A modern podcast publishing solution built as a plugin for WordPress.',
      icon: '../images/icons/podlove-publisher.png',
      link: '/podlove-publisher',
      title: 'Podlove Publisher and Publisher PLUS',
    },
    {
      copy: 'A modern and customizable HTML5 based widget for audio and video playback with chapter support. Built into the Podlove Publisher but usable as a standalone solution with other publishing environments too.',
      icon: '../images/icons/podlove-web-player.png',
      link: '/podlove-web-player',
      title: 'Podlove Web Player',
    },
    {
      copy: 'Universal subscription button that makes it easy to pick and choose a podcast client to subscribe to your podcast feed right from a web page.',
      icon: '../images/icons/podlove-subscribe-button.png',
      link: '/podlove-subscribe-button',
      title: 'Podlove Subscribe Button',
    },
  ]
}