import React from 'react'

const OpenCollective = () => (
  <div
    className="
      flex
      justify-center
      overflow-hidden
      pb-20
      relative
      w-screen
    "
  >
    {/* <div
      className="
        absolute
        bg-white
        h-1/2
        transform
        -translate-y-2/4
        w-screen
      "
    ></div> */}
    <iframe
      src="https://opencollective.com/embed/podlove/donate"
      className="
        inset-0
        w-screen
        min-h-screen
      "
    ></iframe>
  </div>
)

export default OpenCollective