export const DONATION_OPTIONS_DATA = {
  options: [
    {
      copy: 'You can become a patron of our project and invest in the freedom of podcasting. A monthly contribution would do a great deal for us since it helps us cut away time from other paid projects to work exclusively on Podlove. If you’re already relying on any software by Podlove, your contribution ensures its maintenance and longevity.',
      cta_label: 'Support us on Open Collective',
      cta_path: 'https://opencollective.com/podlove',
      icon: '../images/icons/open-collective--white.png',
      title: 'Become our patron',
    },
    {
      copy: 'Are you interested in doing some Open Source work? We are looking for programmers and also UI, UX or other designers. Take a look at our projects on Github, or join one Discord server, where we also meet every second week for a roundtable discussion. Of course, you can also write us an email.',
      cta_label: 'Podlove on Github',
      cta_path: 'https://github.com/podlove',
      icon: '../images/icons/notebook--white.png',
      title: 'Get involved',
    },
    {
      copy: 'Are you a Podcaster and already using one of our tools? Do you want to give something back? Your input and feedback is valuable to us and we are also looking for beta testers. To join our community you can reach out to us on the social networks, via email, or join Discord server and its bi-weekly roundtable meeting.',
      cta_label: 'Roundtable in Discord',
      cta_path: 'https://discord.com/invite/tHnGgvg',
      icon: '../images/icons/podcast--white.png',
      title: 'Give feedback',
    },
  ]
}