import React from 'react'

import { getRandomColor, getRandomDarkColor } from '../helpers/colors'
import Animation from '../elements/animation'

import { DONATION_OPTIONS_DATA } from '../content/donationOptions'

const DonationOptions = () => (
  <div
    className="
      flex
      flex-col
      flex-wrap
      font-sans
      justify-center
      px-12
      py-20
      w-screen
      space-y-8
      text-blue-800
      tracking-wide
      max-w-6xl
      relative
    "
    id="support-us"
  >
    <Animation />
    <h2
      className="
        font-black
        leading-snug
        text-5xl
        max-w-2xl
        z-10
      "
    >What We&apos;d Like</h2>
    <p
      className="
        text-xl
        max-w-2xl
        z-10
      "
    >We believe in Open Source software, because like in podcasting it&apos;s a way to let different people share and contribute ideas to create a better product. Open Source Software not only can stand its ground against some big commercial players, it also has the huge potential to get better and easier to use along the way.</p>
    <p
      className="
        text-xl
        max-w-2xl
        z-10
      "
    >You can directly support this idea and the Podlove projects – here&apos;s how:</p>
    <div
      className="
        flex
        flex-wrap
        md:px-12
        max-w-6xl
        z-10
      "
    >
      { DONATION_OPTIONS_DATA.options.map(item =>
        <div
          key={JSON.stringify(item)}
          className="
            text-left
            py-12
            px-4
            w-full
            sm:w-1/2
            lg:w-1/3
          "
        >
          <div
            className={`
              bg-${getRandomColor()}
              rounded-md
              h-10
              my-4
              w-10
            `}
          >
            <img src={item.icon} />
          </div>
          <h3
            className="
              font-bold
              pb-4
              text-xl
            "
          >{item.title}</h3>
          <p>{item.copy}</p>
          <div
            className="
              py-8
            "
          >
            <a
              className={`
                bg-${getRandomColor()}
                font-medium
                pb-2
                pl-5
                pr-5
                pt-2
                rounded-md
                text-gray-100
                hover:bg-${getRandomDarkColor()}
                transition
                duration-500
                ease-in-out
              `}
              href={item.cta_path}
              target="_blank"
              rel="noreferrer"
            >{item.cta_label}</a>
          </div>
        </div>
      )}
    </div>
    <div id="opencollective"></div>
  </div>
)

export default DonationOptions